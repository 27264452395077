import React from "react";

const mind = () => {
  return (
    <div className="mind">
      <div className="mind-container">
        <div className="text-container">
          <h2 className="title">Ace Ballet</h2>
          <p className="text">
            Ace
            Balletは、「ひとりひとりが輝いてほしい」との想いから名付けました。
          </p>
          <p className="text">
            私自身幼いころはとても引っ込み思案で、並ぶ順番もいつもいちばん端っこでした。
          </p>
          <p className="text">
            学校でも社会でも、きらきらした存在にみえる人を前に、
          </p>
          <p className="text">
            自分のことなんて誰もみていない、とさえどこかで感じてしまったり。
          </p>
          <p className="text">
            そんなときは誰でも、自分は主役じゃないなあ、と思ってしまうのではないでしょうか。
          </p>
          <p className="text">
            でも、バレエを楽しむことが一番の目的であるスタジオで、そんな思いは誰にもしてほしくない。
          </p>
          <p className="text">
            これからバレエ界で羽ばたく子どもたち、バレエを日常にプラスして楽しんでいく子どもたち、
          </p>
          <p className="text">
            大人バレエの方々にとっても、普段は社会のなかで押しつぶしているような感情を、表現することで発散できる場でありたい。
          </p>
          <p className="text">たくさんのうちのひとり、じゃない。</p>
          <p className="text">
            ひとりひとりが、ダイヤのエースのように唯一無二の輝きを持っていてほしい。
          </p>
          <p className="text">
            プロのバレエダンサーを目指す子も、楽しんで続けていきたい方も、息抜きに踊りたい大人の方も、
          </p>
          <p className="text">みんな、ここで輝いていてください。</p>
          <p className="signature">代表 金子珠美</p>
        </div>

        <img src="about-us.jpg" class="about-us-pic" />
      </div>
    </div>
  );
};

export default mind;
