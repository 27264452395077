import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Top from "./Top";
import HeaderScrollHandler from "./HeaderScrollHandler";
import FooterComponent from "./Footer";
import Profile from "./Profile";
import Schedule from "./Schedule";
import Studio from "./Studio";
import Mind from "./mind";
import Saturday from "./Saturday";
import Sunday from "./Sunday";
import Tuesday from "./tuesday";
import "./App.scss";

function App() {
  return (
    <Router>
      <div>
        <HeaderScrollHandler />
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/mind" element={<Mind />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/schedule/*" element={<Schedule />} />
          <Route path="/studio" element={<Studio />} />
          <Route path="/schedule/tuesday" element={<Tuesday />} />
          <Route path="/shedule/saturday" element={<Saturday />} />
          <Route path="/shedule/sunday" element={<Sunday />} />
        </Routes>
        <FooterComponent />
        <a
          className="instagram-container"
          href="https://www.instagram.com/tamamistyle_"
        >
          <img src="Instagram.png"></img>
        </a>
      </div>
    </Router>
  );
}

export default App;
