import React, { useEffect, useState } from "react";

const Top = () => {
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".box");
      elements.forEach((element, index) => {
        const rect = element.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (rect.top <= windowHeight && !isVisible1 && index === 0) {
          setIsVisible1(true);
        }
        if (rect.top <= windowHeight && !isVisible2 && index === 1) {
          setIsVisible2(true);
        }
        if (rect.top <= windowHeight && !isVisible3 && index === 2) {
          setIsVisible3(true);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // 初回レンダリング時にも実行
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible1, isVisible2, isVisible3]);

  return (
    <div className="top">
      <div className="top-image-container">
        <img src="/first-view_ballet.jpg" className="first-view" />
        <div className="title-container">
          <span className="title1">一人一人が</span>
          <br />
          <span className="title2">ダイヤのエース</span>
        </div>
      </div>
      <div className="top-text-container">
        <h1>千葉県柏市のバレエ教室 - バレエの世界へ一歩を踏み出そう！</h1>
        <p>千葉県柏市で優れたバレエ教室をお探しの方へ。</p>
        <p>
          当教室では、複数のバレエ団経験や バレエYoutuberヤマカイさん手がける
          『アラジン - The Ballet Show』に出演等
          豊富な経験と情熱をもって、バレエの美しさと技術をお教えしています。
        </p>
        <h2>なぜ当教室を選ぶべきか？</h2>
        <div className={`box ${isVisible1 ? "show" : ""}`}>
          <div className="item">
            <h3>経験豊富な専門の指導者</h3>
            <p>
              当教室のインストラクターは、国内外で豊富な経験を積んだプロフェッショナル。現役で活躍をしている指導者が生徒一人ひとりに焦点を当て、丁寧で質の高い指導を提供します。
            </p>
          </div>
          <img src="lecture.jpg"></img>
        </div>
        <div className={`box ${isVisible2 ? "show" : ""}`}>
          <img src="option.jpg"></img>
          <div className="item">
            <h3>広いクラスオプション</h3>
            <p>
              初心者から上級者まで、あらゆるレベルに対応したクラスをご用意致しております。年齢や経験に合わせたレッスンで、個々の目標に向けて成長できます。また広々としたスタジオでバレエの練習ができる理想的な環境を整えています。
            </p>
          </div>
        </div>
        <div className={`box ${isVisible3 ? "show" : ""}`}>
          <div className="item">
            <h3>アットホームな雰囲気のバレエ教室</h3>
            <p>
              アットホームで温かい雰囲気の中、バレエを楽しみながら学べる教室です。基礎からステップアップまで、専任のインストラクターが分かりやすく指導します。堅苦しさは一切なく、バレエの世界を楽しみながら学ぶことができます。
            </p>
          </div>
          <img src="experience.jpg"></img>
        </div>
        <div className="top-contact">
          <h2>お問い合わせと体験レッスン</h2>
          <p>
            バレエの世界に興味をお持ちの方、またはお子様を通して芸術的なスキルを身につけたい方は、ぜひ当教室へお気軽にお問い合わせください。一緒にバレエの美しさを追求し、柏市で新しい世界に挑戦しましょう。なお、幼児、子供のレッスンは順次開講予定です。
          </p>
          <a href="https://lin.ee/uMteORX" class="button">
            LINEでお問い合わせ
          </a>
        </div>
      </div>
    </div>
  );
};

export default Top;
