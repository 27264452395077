import React, { useEffect, useState } from "react";

const ProfileComponent = () => {
  return (
    <div className="profile">
      <h2 className="profile-title">
        Profile<span className="small">プロフィール</span>
      </h2>
      <div className="profile-container">
        <div className="profile-image-container">
          <img src="profile1.jpg" class="profile-pic" />
          <img src="profile2.jpg" class="profile-pic adjust" />
          <img src="profile3.jpg" class="profile-pic" />
          <img src="profile4.jpg" class="profile-pic" />
        </div>
        <div className="profile-text-container">
          <h2>金子 珠美 Tamami Kaneko</h2>
          <p>３歳よりクラシックバレエを始める。</p>
          <p>田中静香に師事、ワガノワ・メソッドを学ぶ。</p>
          <p>Moorland Ballet Academy Summer Course参加。</p>
          <p>Mr. Simon Kidd, Ms. Lynsey Kiddに師事。</p>
          <p>
            クラシックバレエ、コンテンポラリーダンス、ボディコンディショニングを学び、高い表現力を評価される。
          </p>
          <p>
            ロシア国立ペルミバレエ学校日本校にて、Ms. Khamzina Elena, Ms.
            Koreneva Veraに師事。
          </p>
          <p>高い表現力を評価される。</p>
          <p>
            ペルミ・ワガノワ・メソッドのクラシックバレエ、キャラクターダンス、ヒストリカルダンスの教授法を学び、
          </p>
          <p>ワガノワ・メソッド教授法取得証明書(Diploma)取得。</p>
          <p>みなとシティバレエ団に在籍中、高い表現力を評価され、</p>
          <p>『シンデレラ』の継母役に抜擢、好評を博す。</p>
          <p>
            『ドン・キホーテ』よりジプシーたちの踊り、『海賊』より奴隷の踊り、
          </p>
          <p>『コッペリア』よりチャルダッシュなどで、さらに魅せ方を磨く。</p>
          <p>学習院大学文学部英語英米文化学科卒業、教員免許取得。</p>
          <p>現在フリーダンサーとして踊りながら、</p>
          <p>クラシックバレエ講師・キャラクターダンス講師として活動中。</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
