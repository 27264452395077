import React from "react";

const Tuesday = () => {
  return (
    <div className="schedule-week-item">
      <div className="week">火曜日</div>
      <div className="week-item">
        <div className="time">13:45</div>
        <div className="card">
          <div className="class">大人のバレエクラス</div>
          <div className="place">
            柏HADスタジオ 千葉県柏市明原1-2-4 カプリコーンビル2F
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tuesday;
