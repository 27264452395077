import React from "react";

const Saturday = () => {
  return (
    <div className="schedule-week-item">
      <div className="week">第1•第3土曜日</div>
      <div className="week-item">
        <p className="time">12:45</p>
        <div className="card">
          <div className="class">クラシックバレエと バロックダンス</div>
          <div className="place">読売カルチャー柏</div>
        </div>
      </div>
    </div>
  );
};

export default Saturday;
