import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HamburgerMenu = ({ isOpen, onToggle }) => {
  const iconClass = isOpen ? "close-icon" : "hamburger-icon";

  return (
    <div className="hamburger-menu">
      <div className={iconClass} onClick={onToggle}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      {isOpen && (
        <div className="menu-modal">
          <div className="menu-items">
            <Link to="/" onClick={onToggle}>
              ホーム
            </Link>
            <Link to="/mind" onClick={onToggle}>
              想い
            </Link>
            <Link to="/profile" onClick={onToggle}>
              教師について
            </Link>
            <Link to="/schedule" onClick={onToggle}>
              開催日程
            </Link>
            <Link to="/studio" onClick={onToggle}>
              スタジオ
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const ScrollHandler = () => {
  const [scrollY, setScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    // コンポーネントがアンマウントされたときにクリーンアップ
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundColor = scrollY > 120 ? "pink" : "transparent";

  return (
    <div className={`header ${backgroundColor}`}>
      <div className="header-container-left">
        <Link to="/">
          <div className="logo">Ace Ballet</div>
        </Link>
      </div>
      {/* ハンバーガーメニューコンポーネントをここに配置 */}
      <HamburgerMenu isOpen={menuOpen} onToggle={handleToggleMenu} />
      <div className="header-container-right">
        <Link to="/mind">想い</Link>
        <Link to="/profile">教師について</Link>
        <Link to="/schedule">開催日程</Link>
        <Link to="/studio">スタジオ</Link>
      </div>
    </div>
  );
};

export default ScrollHandler;
