import React, { useEffect, useState } from "react";

const StudioComponent = () => {
  return (
    <div className="studio">
      <h2 className="studio-title">
        Studio<span className="small">スケジュール</span>
      </h2>
      <div className="studio-container">
        <div className="studio-wrapper">
          <img src="HAD.jpg"></img>
          <div className="studio-item">
            <h3>柏HADスタジオ</h3>
            <p>〒277-0843</p>
            <p>千葉県柏市明原1-2-4 カプリコーンビル2F</p>
            <p>柏駅西口から徒歩6分</p>
            <a
              href="https://maps.app.goo.gl/GQL6tnUNwDahxfa59"
              className="map-detail"
            >
              詳しく見る
            </a>
          </div>
        </div>
        <div className="studio-wrapper">
          <img src="yomiuri.jpg"></img>
          <div className="studio-item">
            <h3>よみうりカルチャー 柏</h3>
            <p>〒277-0005</p>
            <p>千葉県柏市柏1-1-20 スカイプラザ8F</p>
            <p>柏駅東口からすぐ</p>
            <a
              href="https://maps.app.goo.gl/bgq6xSWvDCfUENqM9"
              className="map-detail"
            >
              詳しく見る
            </a>
          </div>
        </div>
        <div className="studio-wrapper">
          <img src="ion.webp"></img>
          <div className="studio-item">
            <h3>JEUGIA カルチャー</h3>
            <p>〒343-0828</p>
            <p>埼玉県越谷市レイクタウン 3-1-1 MORIエリア 3F</p>
            <p>越谷レイクタウン駅からすぐ</p>
            <a href="https://g.co/kgs/HMq39fb" className="map-detail">
              詳しく見る
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudioComponent;
