import React from "react";

const ScheduleComponent = () => {
  return (
    <div className="schedule" id="schedule">
      <div className="schedule-title">
        Schedules<span className="small">スケジュール</span>
      </div>
      <div className="schedule-week-item">
        <div className="week">火曜日</div>
        <div className="week-item">
          <div className="time">13:15</div>
          <div className="card-wrapper">
            <div className="card">
              <div className="card-image">
                <img src="teacher_profile.jpg" />
              </div>
              <div className="card-item">
                <div className="detail-time">13:15〜14:45 </div>
                <div className="name">金子 珠美</div>
                <div className="class">
                  大人
                  <br />
                  バレエクラス
                </div>
                <div className="place">柏HADスタジオ</div>
                <a href="https://lin.ee/uMteORX" className="schedule-contact">
                  LINE問い合わせ
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="schedule-week-item">
        <div className="week">第1•第3土曜日</div>
        <div className="week-item">
          <div className="time">12:45</div>
          <div className="card-wrapper">
            <div className="card">
              <div className="card-image">
                <img src="teacher_profile.jpg" />
              </div>
              <div className="card-item">
                <div className="detail-time">12:45〜14:00 </div>
                <div className="name">金子 珠美</div>
                <div className="class">
                  <p>大人</p>
                  <p>クラシックバレエと</p>
                  <p>バロックダンス</p>
                </div>
                <div className="place">読売カルチャー柏</div>
                <a href="https://lin.ee/uMteORX" className="schedule-contact">
                  LINE問い合わせ
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="schedule-week-item">
        <div className="week">第2•第4日曜日</div>
        <div className="week-item">
          <div className="time">13:00</div>
          <div className="card-wrapper">
            <div className="card">
              <div className="card-image">
                <img src="teacher_profile.jpg" />
              </div>
              <div className="card-item">
                <div className="detail-time">13:00〜13:50 </div>
                <div className="name">金子 珠美</div>
                <div className="class">
                  2,3歳 ~ 就学前
                  <br />
                  リトルバレエクラス
                </div>
                <div className="place">越谷イオンレイクタウン</div>
                <a href="https://lin.ee/uMteORX" className="schedule-contact">
                  LINE問い合わせ
                </a>
              </div>
            </div>
            <div className="card">
              <div className="card-image">
                <img src="teacher_profile.jpg" />
              </div>
              <div className="card-item">
                <div className="detail-time">13:50〜14:50 </div>
                <div className="name">金子 珠美</div>
                <div className="class">
                  小学1〜3年生
                  <br />
                  リトルバレエクラス
                </div>
                <div className="place">越谷イオンレイクタウン</div>
                <a href="https://lin.ee/uMteORX" className="schedule-contact">
                  LINE問い合わせ
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="schedule-description-container">
        <h2>クラス概要</h2>
        <p>
          どのクラスも未経験の方、初めての方にお勧めになっております。
          <br />
        </p>
        <p>
          Ace Balletでは バレエレッスンの基礎ともいえる「バーレッスン」
          「バレエストレッチ」 でバレエを踊る為に必要な身体づくりができます。
        </p>
        <p>
          アットホームな雰囲気作りを心がけており、初心者、経験者未経験者の皆様が楽しめるよう工夫をしています。
        </p>
        <p>
          質問がございましたら、LINEからお問い合わせ頂けたら何でもお答え致します.
          お待ちしております。
        </p>
      </div>
    </div>
  );
};

export default ScheduleComponent;
