import React from "react";
import { CiInstagram } from "react-icons/ci";
import { CiFacebook } from "react-icons/ci";
import { CiMail } from "react-icons/ci";

const FooterComponent = () => {
  return (
    <div className="footer" id="contact">
      <div className="footer-container">
        <div className="title">Ace Ballet</div>
        <div className="contact-container">
          <a href="https://www.instagram.com/tamamistyle_" className="insta">
            <CiInstagram />
            <div>instagram</div>
          </a>
          <a className="facebook">
            <CiFacebook />
            <div>facebook</div>
          </a>
          <div className="mail">
            <CiMail />
            <div>ace.ballet.academy@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
