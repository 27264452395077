import React from "react";

const Sunday = () => {
  return (
    <div className="schedule-week-item">
      <div className="week">第2•第4日曜日</div>
      <div className="week-item">
        <div className="time">13:00</div>
        <div className="card">
          <div className="class">リトルバレエクラス 2,3歳 ~ 就学前</div>
          <div className="place">
            JERGIAカルチャーセンターイオンレイクタウン
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sunday;
